.rec.rec-slider-container {
    overflow: visible;
}

.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px violet;
}


